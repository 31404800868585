@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

.custom-shape-divider-top-1675007373 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-top-1675007373 svg {
    position: relative;
    display: block;
    width: calc(119% + 1.3px);
    height: 188px;
}

.custom-shape-divider-top-1675007373 .shape-fill {
    fill: #111116;
}

/*Default settings*/
* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

/*Root CSS Variable*/
:root{
    --color-bg: #475c74;
    --color-bg-variant:#577377;
    --color-primary: #6ab3bd;
    --color-primary-variant: rgba(76, 0, 252, 0.4);
    --color-white: #fff;
    --color-light: rgba(255, 255, 255, 0.788);

    --transition: all 400ms ease;  

    --container-width-lg: 75%;
    --container-width-md: 86%;
    --container-width-sm: 90%;

}

html
{
    scroll-behavior: smooth;
}

::-webkit-scrollbar{
    display:none;
}

body{
    font-family: 'Poppins', sans-serif;
    background: var(--color-bg);
    color: var(--color-white);
    line-height: 1.7;
}

/*General Styles*/
.container{
    width: var(--container-width-lg);
    margin: 0 auto;

}

h1,h2,h3,h4,h5{
    font-weight: 500;

}

h1{
    font-size: 2.5rem;
}

section{
    margin-top: 7rem;
}



section > h2, section > h5{
    text-align: center;
    color: var(--color-light);

}

section > h2 {
    color: var(--color-primary);
    margin-bottom: 3rem;
}

.text-light{
    color: var(--color-light);
}

a{
    color: var(--color-primary);
    transition : var(--transition);
}

a:hover{
    color: var(--color-white);

}

.btn{
    width: max-content;
    display: inline-block;
    color: var(--color-primary);
    padding: .75rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    transition: var(--transition);

}

.btn:hover{
    background: var(--color-white);
    color:var(--color-bg);
    border-color: transparent;
}

.btn-primary{
    background: var(--color-primary);
    color: var(--color-bg);
}

img{
    display: block;
    width: 100%;
    object-fit: cover;
}


/*Media Queries (Medium Devices)*/
@media screen and (max-width: 1024px){
    .container{
        width:var(--container-width-md);
    }

    section{
        margin-top: 6rem;
    }
}

/*Media Queries (Small Devices)*/
@media screen and (max-width: 600px){
    .container{
        width:var(--container-width-sm);
    
    }

    section > h2{
        margin-bottom: 2rem;
    }
}

