.container.contact_container{
    width: 58%;
    display: grid;
    grid-template-columns: 30% 58%;
    gap: 12%;
}

.contact_options {
    display:flex ;
    flex-direction: column;
    gap: 1.2rem;
}

.contact_option{
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: center;
    margin-top: 15%;
    margin-bottom: 30%;
    border: 1px solid transparent;
    height: 100%;
    transition: var(--transition);
}

.contact_option:hover{
    background: transparent;
    border-color: var(--color-primary-variant);

}

.contact_option-icon{
    font-size: 1.5rem;
    margin-bottom: 2.0rem;
}

.contact_option a{
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 0.8rem;
    text-align: center;
}

/*FORM*/
form{
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

input, textarea{
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: transparent;
    border: 2px solid var(--color-light);
    color: var(--color-light);
    resize: none;
    color: var(--color-white);
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color:  var(--color-light);
  }
/*Media Query Medium Devices*/
@media screen and (max-width: 1024px){
    .container.contact_container{
        grid-template-columns: 1fr;
        gap: 2rem;
    }

}

/*Media Query Small Devices*/
@media screen and (max-width: 1024px){
    .container.contact_container{
        width: var(--container-width-sm);
    }
}